// Block Sections
.section-wrap-inner {
   h2.cb-sectionHeading, .section-text-inset {
      display: block;
      max-width: 820px;
      margin-right: auto;
      margin-left: auto;
   }
}
//.section-wrap-alt-inner {
//   h2.sectionHeading {
//      display: block;
//      max-width: 820px;
//      margin-right: auto;
//      margin-left: auto;
//   }
//}

// Blockquote
.cb-blockquote {
   background-color: $brand-lighter-blue;
   border-radius: 0.25rem;
   color: $secondary;
   margin-top: 1.5rem;
   margin-bottom: 1.5rem;
   padding: 1.25rem 1.5rem;
   .blockquote-footer {
      color: #4B5157;
   }
   &.cb-blockquote-center {
      padding-left: 15px;
      max-width: 820px;
      margin-right: auto;
      margin-left: auto;
   }
   &.cb-blockquote-left,
   &.cb-blockquote-right {
      @include media-breakpoint-up(md) {
         width: 45%;
      }
      @include media-breakpoint-up(xmd) {
         width: 35%;
      }
   }
   &.cb-blockquote-left {
      @include media-breakpoint-up(md) {
         float: left;
         margin-right: 30px;
      }
   }
   &.cb-blockquote-right {
      @include media-breakpoint-up(md) {
         float: right;
         margin-left: 30px;
      }
   }
}

// Buttons
.cb-buttons {
   margin-top:1.875rem;
   .btn {
      margin-bottom:.5rem;
      &:not(:last-of-type) {
         margin-right: .5rem;
      }
   }
}

// Employees

[id*='employees-board-members-'] {
   &.cb-employees {
      &[class*="employees-"] .col {
         .media {
            margin-bottom: 1.875rem!important;
            .media-header {
               margin-bottom:0;
            }
         }
      }
   }
}
.cb-employees {
   .employee-card {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 1.5rem;
      .employee-card-img {
         overflow: hidden;
      }
      .card-body {
         padding-right: 0;
         padding-left: 0;
      }
      @include media-breakpoint-up(sm) {
         margin: 1.5rem 0;
      }
   }
   @media only screen and (min-width:576px) and (max-width: 991px) {
      &.employees-1 {
         .card {
            flex-direction: row;
         }

         .employee-card-img {
            width: 25%;
         }

         .card-body {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 1.25rem;
            width: 75%;
            align-self: center;
         }
      }
   }
   @media only screen and (min-width:576px) and (max-width: 767px) {
      &.employees-2, &.employees-3, &.employees-4 {
         .card {
            flex-direction: row;
         }

         .employee-card-img {
            width: 25%;
         }

         .card-body {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 1.25rem;
            width: 75%;
            align-self: center;
         }
      }
   }
   @media only screen and (min-width:768px) and (max-width: 991px) {
      &.employees-2, &.employees-3, &.employees-4 {
         .employee-card {
            flex: 0 0 50%;
            max-width: 50%;
         }
      }
   }
   @media only screen and (min-width:992px) {
      &.employees-1 {
         .card {
            flex-direction: row;
         }

         .employee-card-img {
            width: 16.66667%;
         }

         .card-body {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 1.25rem;
            width: 83.33333%;
            align-self: center;
         }
      }
      &.employees-2, &.employees-3, &.employees-4 {
         .employee-card {
            flex: 0 0 50%;
            max-width: 50%;

            .card {
               flex-direction: row;
            }

            .employee-card-img {
               width: 33.33333%;
            }

            .card-body {
               padding-top: 0;
               padding-bottom: 0;
               padding-left: 1.25rem;
               width: 66.66667%;
            }
         }
      }
   }
   &.employees-3 {
      @include media-breakpoint-up(lg) {
         .employee-card {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            .card {
               flex-direction: column;
            }
            .employee-card-img {
               width: 100%;
            }
            .card-body {
               padding: 1.25rem 0;
               width: 100%;
            }
         }
      }
   }
   &.employees-4 {
      @include media-breakpoint-up(lg) {
         .employee-card {
            flex: 0 0 25%;
            max-width: 25%;
            .card {
               flex-direction: column;
            }
            .employee-card-img {
               width: 100%;
            }
            .card-body {
               padding-top: 1.25rem;
               padding-bottom: 1.25rem;
               padding-right: 0;
               padding-left: 0;
               width: 100%;
            }
         }
      }
   }
}

// Form & HTML Block
.cb-form, .cb-html, .cb-plainText {
   margin-top:1.25rem;
   margin-bottom:1.25rem;
}

// Image Block
.cb-image {
   overflow: hidden;
   img {
      width: 100%;
      @include media-breakpoint-down(md) {
         display: block;
         margin-right: auto;
         margin-left: auto;
      }
   }
   .figure-caption {
      text-align: center;
      p:last-of-type {
         margin-bottom: 0;
      }
   }
   &.entry-image-full,
   &.entry-image-center {
      margin-bottom: 1.875rem;
      margin-top: 1.875rem;
      .figure-caption {
         @include media-breakpoint-up(md) {
            max-width: 820px;
            margin-right: auto;
            margin-left: auto;
         }
      }
   }
   &.entry-image-full {
      .figure-caption {
         @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
         }
      }
   }
   &.entry-image-full {
      margin-right: -15px;
      margin-left: -15px;
      img {
         border-radius:0!important;
         width: 100%;
      }
   }
   &.entry-image-left,
   &.entry-image-right {
      @include media-breakpoint-up(md) {
         width: 40%;
      }
   }
   &.entry-image-left {
      @include media-breakpoint-up(md) {
         float: left;
         margin-right: 30px;
         // margin-left: 15px;
      }
   }
   &.entry-image-right {
      @include media-breakpoint-up(md) {
         float: right;
         margin-left: 30px;
         // margin-right: 15px;
      }
   }
   @include media-breakpoint-up(md) {
      .figure-caption {
         text-align: left;
      }
   }
}

// Video Block
.cb-video {
   margin-bottom: 1rem;
   margin-top:1.875rem;
   .col {
      margin-bottom: 1.5rem;
      &:only-child {
         padding-right:0;
         padding-left:0;
      }
      &:nth-child(odd) {
         padding-left:0;

      }
      &:nth-child(even) {
         padding-right:0;

      }
      &:not(:only-child) {
         flex: 0 1 100%;
      }
      @include media-breakpoint-up(md) {
         &:not(:only-child) {
            flex: 0 1 50%;
            max-width: 50%;
         }
      }
      @include media-breakpoint-down(sm) {
         padding-left:0;
         padding-right:0;
      }
   }
}
