//@import url("https://use.typekit.net/tjz5nzk.css");
//
//@font-face {
//  font-family: 'Ernestine Pro Regular';
//  font-weight: 400;
//  font-style: normal;
//}

@import url("https://p.typekit.net/p.css?s=1&k=tjz5nzk&ht=tk&f=15279.15280.15283.15286&a=339555&app=typekit&e=css");

@font-face {
  font-family:"Ernestine Pro Bold";
  src:url("https://use.typekit.net/af/04ac73/00000000000000003b9b0544/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),
      url("https://use.typekit.net/af/04ac73/00000000000000003b9b0544/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),
      url("https://use.typekit.net/af/04ac73/00000000000000003b9b0544/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
  font-family:"Ernestine Pro Bold It";
  src:url("https://use.typekit.net/af/f3923a/00000000000000003b9b0545/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),
      url("https://use.typekit.net/af/f3923a/00000000000000003b9b0545/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),
      url("https://use.typekit.net/af/f3923a/00000000000000003b9b0545/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
  font-family:"Ernestine Pro It";
  src:url("https://use.typekit.net/af/75085b/00000000000000003b9b0548/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),
  url("https://use.typekit.net/af/75085b/00000000000000003b9b0548/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),
  url("https://use.typekit.net/af/75085b/00000000000000003b9b0548/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
  font-family:"Ernestine Pro Regular";
  src:url("https://use.typekit.net/af/02b875/00000000000000003b9b0543/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
      url("https://use.typekit.net/af/02b875/00000000000000003b9b0543/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
      url("https://use.typekit.net/af/02b875/00000000000000003b9b0543/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;
}