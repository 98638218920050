$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  xmd: 992px,
  lg: 1200px,
  xl: 1440px,
  xxl: 1800px,
);

$enable-responsive-font-sizes: true;

// Colors
$primary:               #1d87cd;
$secondary:             #002b3e;
$brand-yellow:          #AAC84E;
$brand-orange:          #AACCD9;
$brand-lt-blue:         #00aeef;
$brand-md-blue:         #006fbf;
$brand-lighter-blue:    #e5f2fa;
$headings-color:        $secondary;

// Cards
$card-bg: $brand-lighter-blue;
$card-border-radius: 0;

$adminBar: 37px;

$lead-font-size: 1.75rem;

$font-weight-bold: 500;
$font-weight-bolder: $font-weight-bold;