

.card-event {
  .card-header {
    display: flex;
    align-items: center;
    padding-top: 1.25rem;
    padding-bottom: 0;
  }
  .card-title {
    color: $link-color;
  }
}

.event-single-wrapper {
  display: flex;
}

.event-single-content {
  flex: 1 1
}

.event-meta {

}

.event-date-text {
  font-weight: $font-weight-bold;
  color: $secondary;
}

.event-date {
  box-shadow: 0 2px 6px rgba(black, 0.3);
  font-weight: $font-weight-bold;
  width: 75px;
  .event-date-day {
    font-size: 2.125rem;
  }
}
