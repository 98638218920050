.header-logo-svg {
  //max-width: 225px;
  width: 220px;
  @include media-breakpoint-up(sm) {
    //max-width: 260px;
    width: 260px;
  }
}

.site-header .navbar {
  padding: 1rem;
}
.site-header {
  .main-navbar-nav {
    .nav-item {
      &:not(.nav-item-Request-a-Quote) {
        .nav-link.active {
          color: $secondary;
        }
      }

      @include media-breakpoint-up(xmd) {
        margin-right: 1.25rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .search-nav-item {
      @include media-breakpoint-up(xmd) {
        display: none;
      }
    }
  }
}

.utility-nav {
  .search-nav-item {
    margin-right: 1rem;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.navbar-brand {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  @include media-breakpoint-up(xmd) {
    align-self: flex-end;
  }
}
.nav-item-Request-a-Quote {
  a {
    @extend .btn-yellow;
  }
  .nav-link {
    padding: 0.375rem 0.75rem;
  }
}
.navbar-toggler {
  &.collapsed {
    -webkit-box-shadow: 0 0 0 rgba($brand-lt-blue, 0);
    -moz-box-shadow: 0 0 0 rgba($brand-lt-blue, 0);
    box-shadow: 0 0 0 rgba($brand-lt-blue, 0);
  }
  background-color: $brand-lighter-blue;
  padding: 0.25rem 0.5rem;
  transition: box-shadow 0.25s ease;
  .hamburger-fillings {
    display: block;
    width: 25px;
    .hamburger-filling {
      background-color: $secondary;
      display: block;
      height: 4px;
      margin: 8px 0;
      transition: transform .15s ease;
    }
  }
  &[aria-expanded='true'] {
    -webkit-box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
    -moz-box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
    box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
    .hamburger-filling {
      &:first-child {
        transform: rotate(-45deg) translate(-4px, 4px);
      }
      &:nth-child(2) {
        transform: rotate(45deg) translate(-4px, -5px);
      }
    }
  }
  &:focus {
    outline: 0;
    -webkit-box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
    -moz-box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
    box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
  }
}
.mobile-nav-icon:before, .mobile-nav-icon:after {
  background-color: #fff;
  border-radius: 3px;
  content: '';
  display: block;
  height: 5px;
  margin: 7px 0;
  transition: all .2s ease-in-out;
}

.sign-in-link {
  .sign-in-icon {
    display: inline-block;
    margin-right: 0.375rem;
    width: 20px;
    height: 20px;
    svg {
      fill: #1d87cd;
      width: 100%;
      height: 100%;
    }
  }
  &:hover {
    svg {
      fill: $secondary;
    }
  }
}
.header-search-form,
.header-search-form > div,
.nav-item-Request-a-Quote .nav-link{
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}
.header-search-form > div {
  position: relative;
  .search-label {
    position: absolute;
    top: 12px;
    left: 10px;
    z-index: 10;
    transform: scale(1) translate(0, 0);
    transition: transform .15s ease;
  }
  #headerSearch{
    background-color: $brand-lighter-blue;
    border-color: transparent;
    height: calc(1.5em + 1rem + 2px);
    padding: 1.25rem 0.75rem 0.5rem;
    &.hasValue {
      + .search-label {
        transform: scale(.85) translate(-15px, -15px);
      }
    }
    &:focus {
      box-shadow: none;
      + .search-label {
        transform: scale(.85) translate(-15px, -15px);
      }
    }
  }
}