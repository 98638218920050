.site-footer {
  @extend .bg-dark-blue;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer-logo-svg-wrapper {
  //@include media-breakpoint-down(lg) {
  //  display: flex;
  //  flex-grow: 1;
  //  flex-basis: 100%;
  //}
}
.footer-logo-svg {
  //max-width: 225px;
  width: 225px;
  height: 22px;
  @include media-breakpoint-up(sm) {
    width: 260px;
    height: 25px;
  }
}
.footer-nav {
  .nav-item {
    &:not(.nav-item-Request-a-Quote) {
      .nav-link {
        color: $brand-lt-blue;
        outline-offset: 5px;
        outline: 2px solid transparent;
        transition: outline-offset .2s linear;
        &:hover {
          background-color: darken($secondary, 2.5%);
        }

        &:focus {
          background-color: darken($secondary, 2.5%);
          //box-shadow: 0 0 0 .2rem rgba($brand-md-blue, .75);
          outline: 2px solid rgba($brand-md-blue, .75);
          outline-offset: 2px;
        }
      }
    }
    @include media-breakpoint-up(xmd) {
      &:last-child {
        margin-left:1rem;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    margin-left: 2rem;
  }
}
.footer-connect {
  @extend .text-yellow;
  padding: 0.5rem 1rem;
  @include media-breakpoint-up(xmd) {
    margin-left: auto;
  }
  .footer-connect-link {
    cursor: pointer;
    display: inline-block;
    padding: 0.125rem;
    svg {
      fill: #ffffff;
    }
    &:hover {
      background-color: darken($secondary, 2.5%);
    }
    &:focus {
      box-shadow: 0 0 0 .2rem rgba($brand-md-blue, .75);
    }
  }
  .footer-connect-social {
    display: inline-block;
    width: 35px;
    height: 35px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}