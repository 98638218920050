body {
  font-family: "Ernestine Pro Regular", sans-serif;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Ernestine Pro Bold", sans-serif;
}

b, strong {
  font-family: "Ernestine Pro Bold", sans-serif;
}

a {
  outline-offset: 5px;
  outline: 2px solid transparent;
  transition: outline-offset .2s linear;
  &:focus {
    outline: 2px solid $secondary;
    outline-offset: 2px;
  }
}
// Images
img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  flex-shrink: 0;
}
.responsive-bg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}
// Responsive Video
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Helper Classes
.text-lt-blue { color: $brand-lt-blue; }
.text-md-blue { color: $brand-md-blue; }
.text-dark-blue { color: $secondary; }
.text-yellow { color: $brand-yellow; }
.text-orange { color: $brand-orange; }

.bg-lighter-blue { background-color: $brand-lighter-blue; }
.bg-lt-blue { background-color: $brand-lt-blue; }
.bg-md-blue { background-color: $brand-md-blue; }
.bg-dark-blue { background-color: $secondary; }
.bg-yellow { background-color: $brand-yellow; }
.bg-orange { background-color: $brand-orange; }

.btn-yellow {
  @extend .btn, .bg-yellow;
  color: $secondary;
  @include primaryBtnMixin;
}

.btn-dark-blue {
  @extend .btn, .bg-dark-blue;
  color: $brand-yellow;
  @include secondaryBtnMixin;
}

.btn-white {
  @extend .btn, .bg-white;
  color: $secondary;
  @include whiteBtnMixin;
}

.btn-lt-blue {
  @extend .btn, .bg-lighter-blue;
  color: $secondary;
  @include whiteBtnMixin;
}

.section-margin {
  margin-top: 3rem;
  margin-bottom: 3rem;
  @include media-breakpoint-up(xmd) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.section-padding {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(xmd) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.anchor-tag {
  position: relative;
  top: -3rem;
  @include media-breakpoint-up(xmd) {
    top: -5rem;
  }
}

table {
  @extend .table, .table-responsive, .table-borderless;
  > thead {
    background-color: $brand-lighter-blue;
    color: $secondary;
  }
  tbody tr {
    &:nth-of-type(2n) {
      background-color: $brand-lighter-blue;
    }
    &:hover {
      background-color: $brand-md-blue;
      color: $white;
    }
  }
}

// Pagination
.page-item.active .page-link {
  color: $secondary;
  background-color: $brand-lt-blue;
  border-color: $brand-lt-blue;
}
.page-link{
  &:hover {
    color: $secondary;
  }
  &:focus {
    color: $secondary;
    box-shadow: 0 0 0 .2rem rgba($brand-md-blue, .75);
  }
}

.twitter-timeline .twitter-timeline-rendered {
  overflow: scroll;
  height: 500px;
}