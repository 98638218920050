body {
  min-height: 100vh;
  position: relative;
}
.skip-link {
  position: absolute;
  background-color: $brand-yellow;
  color: $secondary;
  display: block;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  left:-10000px;
  top: 0;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    position: relative;
    left: 0;
    text-decoration: none;
  }
}
// Homepage Path Blocks
[data-responsive-background-image] {
  img {
    position:absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  }
}
.blur-up {
  -webkit-filter: blur(10px);
  filter: blur(10px);
  transition: filter 0.3s linear, -webkit-filter 0.3s linear;
  &.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0);
  }
}
.path-blocks {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 78px);
  .path-block-placeholder-bg, .path-block-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .path-block-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    height: auto;
    width: auto;
    max-width: none;
  }
  //.path-block-bg {
  //  opacity: 0;
  //  transition: opacity 0.3s ease;
  //  &.lazyloaded {
  //    opacity: 1;
  //  }
  //}
  .path-block {
    position: relative;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    overflow: hidden;
    &:before {
      position: absolute;
      content: '';
      background-color: rgba($secondary, .8);
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    &:first-of-type {
      border-bottom: 1px solid #cccccc;
    }
    @include media-breakpoint-up(xmd) {
      height: calc(100vh - 130px);
      &:first-of-type {
        border-right: 1px solid #cccccc;
        border-bottom: 0;
      }
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .path-flag, .path-title {
    letter-spacing: 1px;
    z-index: 1;
    text-transform: uppercase;
  }
  .path-title {
    @include media-breakpoint-up(xmd) {
      font-size: $h1-font-size;
    }
  }
  .path-flag {
    //position: relative;
    //background-color: $brand-orange;
    color: $white;
    font-family: "Ernestine Pro Bold", sans-serif;
    margin-bottom: 0.75rem;
    //padding: 0.5rem 1.25rem;
    //-webkit-transform: skewX(-20deg);
    //-moz-transform: skewX(-20deg);
    //-ms-transform: skewX(-20deg);
    //-o-transform: skewX(-20deg);
    //transform: skewX(-20deg);
    //> span {
    //  display: inline-block;
    //  -webkit-transform: skewX(20deg);
    //  -moz-transform: skewX(20deg);
    //  -ms-transform: skewX(20deg);
    //  -o-transform: skewX(20deg);
    //  transform: skewX(20deg);
    //}
  }
  .path-link {
    color: $brand-lt-blue;
    cursor: pointer;
    outline: 0;
    text-decoration: none;
    &:after {
      content: '';
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 rgba($black, 0);
      transition: box-shadow 0.15s ease;
    }
    &:hover:after {
      box-shadow: inset 0 0 150px rgba($black,.75);
    }
    &:focus:after {
      box-shadow: inset 0 0 150px rgba($black,.75);
    }
    &:active:after {
      border: none;
    }
  }
  @include media-breakpoint-up(xmd) {
    flex-direction: row;
  }
}

.intro-headline {
  @include media-breakpoint-up(xmd) {
    font-size: 3.25rem;
  }
}

// Default Pages
.page-header {
  background-color: $brand-lt-blue;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

// Researcher Landing page
.featured-content-section {
  &:nth-child(2n) {
    @extend .bg-light;
  }
  .product-card-col-img {
    img {
      width: 100%;
      border-radius: 0.25rem;
    }
  }
  @include media-breakpoint-up(xmd) {
    .product-card-col-img {
      position: relative;
      align-self: start;
    }
    &:nth-of-type(2n + 1) .product-card-col-img {
      order: 2;
      margin-left: 8.33333%;
    }
    &:nth-of-type(2n + 1) .product-card-col-text {
      order: 1;
    }
    &:nth-of-type(2n) .product-card-col-text {
      margin-left: 8.33333%;
    }
  }
}
.featured-product-section + .featured-content {
  .featured-content-section {
    &:nth-child(2n + 1) {
      @extend .bg-light;
    }
    &:nth-child(2n) {
      @extend .bg-white;
    }
  }
}

// Producer Landing Pages
.featured-product-section {
  .product-card-row {
    &:first-of-type {
      padding-top: 0;
    }

    .product-card-col-img {
      img {
        width: 100%;
        border-radius: 0.25rem;
      }
    }

    @include media-breakpoint-up(xmd) {
      .product-card-col-img {
        position: relative;
        align-self: start;
      }
      &:nth-of-type(2n + 1) .product-card-col-img {
        order: 2;
        margin-left: 8.33333%;
      }
      &:nth-of-type(2n + 1) .product-card-col-text {
        order: 1;
      }
      &:nth-of-type(2n) .product-card-col-text {
        margin-left: 8.33333%;
      }
    }
  }
}

.product-callout.bg-dark-blue {
  color: $white;
  .product-callout-title {
    color: $white;
  }
}
.product-callout-column,
.blog-entry-pagination {
  @include media-breakpoint-up(md) {
    padding-right: 5%;
    padding-left: 5%;
  }
  @include media-breakpoint-up(lg) {
    padding-right: 8%;
    padding-left: 8%;
  }
}

// Product Full List
.card-img-wrapper,
.blog-img-wrapper {
  min-height: 1%;
}
.product-listing-card {
  overflow: hidden;
  .card-body {
    .btn-yellow {
      &:after {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

// Product Pages
.product-image-cell {
  width: 100%;
  height: 250px;
  overflow: hidden;
  img {
    position: absolute;
    display: block;
    //margin: 0 auto;
    max-height: 100%;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include media-breakpoint-up(sm) {
    height: 400px;
  }
}

.product-image-carousel.is-fullscreen .product-image-cell {
  height: 100%;
}

.product-carousel-nav {
  .flickity-button {
    background: $secondary;
    svg {
      fill: $brand-lt-blue;
    }
  }
  .flickity-prev-next-button {
    border-radius: 0;
    height: 100%;
    width: 40px;
  }

   .product-nav-cell {
    border: 0 solid transparent;
    -webkit-transition: border 0.15s linear;
    -moz-transition: border 0.15s linear;
    -ms-transition: border 0.15s linear;
    -o-transition: border 0.15s linear;
    transition: border 0.15s linear;
    &.is-nav-selected {
      border: 5px solid $brand-md-blue;
    }
  }
  @include media-breakpoint-down(md) {
    .flickity-prev-next-button.previous,
    .flickity-prev-next-button.next {
      display: none;
    }
    height: 100px;
    .product-nav-cell {
      width: 100px;
      height: 100px;
      cursor: pointer;
      margin-right: 1rem;
      font-size: 1.4rem;
    }
  }
  @include media-breakpoint-up(xmd) {
    width: 300px;
    transform-origin: left top;
    transform: rotate(90deg) translate(50px, -100%);
    .product-nav-cell {
      transform: rotate(-90deg);
      width: 100px;
      height: 100px;
      cursor: pointer;
      margin-right: 1rem;
      font-size: 1.4rem;
    }
    .flickity-prev-next-button.previous {
      left: -45px;
    }
    .flickity-prev-next-button.next {
      right: -45px;
    }
  }
}
.product-detail-section {
  position: relative;
  top: -135px;
  height: 0;
}
.product-nav {
  background-color: $brand-lighter-blue;
}
.product-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    position: relative;
    display: inline-block;
    &.product-nav-active-item {
      background-color: $secondary;
      &:after {
        content: '';
        position: absolute;
        bottom: -20px;
        transform: translateX(50%);
        right: 50%;
        border-top: 10px solid $secondary;
        border-bottom: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
      a {
        color: $white;
      }
    }
    > a {
      color: $secondary;
      display: inline-block;
      padding: 1.5rem;
      &:hover {

      }
    }
    @include media-breakpoint-down(sm) {
      display: none;
      > a {
        font-size: .875rem;
        padding: 1rem .75rem;
      }
      &.product-nav-active-item {
        display: inline-block;
      }
    }
  }
}
.feature-list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.feature-list-item {
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  &:before {
    content: '•';
    top: -1px;
    left: 5px;
    position: absolute;
  }
  p {
    display: inline;
  }
  @include media-breakpoint-up(sm) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @include media-breakpoint-up(xmd) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

.product-addon-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
  .option-title, .option {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 1rem 1.5rem;
  }
  .options {
    display: inline-flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
    @include media-breakpoint-up(xmd) {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
  .option-title {
    > h3 {
      color: $white;
    }
    @include media-breakpoint-up(xmd) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    margin-bottom: 4px;
    @include media-breakpoint-up(sm) {
      border-right: 4px solid $brand-lt-blue;
      border-left: 4px solid $brand-lt-blue;
      margin-bottom: 0;
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  &.product-addon-3 {
    .option {
      @include media-breakpoint-up(sm) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
  }
  &.product-addon-2 {
    .option {
      @include media-breakpoint-up(sm) {
        &:first-child {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
        &:nth-child(2) {
          flex: 0 0 66.66667%;
          max-width: 66.66667%;
        }
      }
    }
  }
  .add-on-title {
    position: relative;
    margin-left: 20px;
    margin-top: .5rem;
    &:first-of-type {
      margin-top: 0;
    }
    &:before {
      content: '';
      position: absolute;
      border-radius: 100%;
      background-color: $brand-lt-blue;
      width: 15px;
      height: 15px;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
    }
    @include media-breakpoint-down(sm) {
      font-size: 0.8125rem;
    }
  }
  @include media-breakpoint-up(xmd) {
    flex-direction: row;
  }
}

// Project Pages
.project-details-list {
  font-size: 0.875rem;
}
.publication {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid $secondary;
  //a {
  //  color: $secondary;
  //}
  &:last-child {
    border-bottom: 0;
  }
}

.child-nav {
  .nav-link.active {
    color: $secondary;
  }
}

// Blog
.share-links {
  .list-inline-item {
    margin-right: 0;
  }
  a {
    display: inline-block;
    padding: .5rem .75rem;
  }
}
.social-share-icon-facebook svg {
  width: 12px;
  height: 20px;
}
.social-share-icon-twitter svg {
  width: 20px;
  height: 20px;
}
.social-share-icon-email svg {
  width: 20px;
  height: 20px;
}
.media-blog-wrapper {
  position: relative;
  margin-bottom: 3rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  a.blog-entry-link {
    text-decoration: none;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
  .blog-entry-categories {
    position: relative;
    z-index: 11;
  }
}
.blog-img-wrapper {
  overflow: hidden;
  width: 100%;
  img {
    width: 100%;
  }
  @include media-breakpoint-up(md) {
    max-width: 250px;
  }
}
.media-blog-copy {
  p {
    margin-bottom: 0;
  }
  @include media-breakpoint-down(sm) {
    flex-basis: 100%;
  }
}
.blog-entry-pagination {
  position: relative;
  bottom: 0;
  background-color: #f8f9fa;
  padding-top: 3rem;
  padding-bottom: 3rem;
  &:nth-of-type(2) {
    border-left: 1px solid #dddddd;
  }
  &:hover {
    background-color: $brand-lighter-blue;
  }
  .blog-entry-pagination-link {
    display: inline-block;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

#blog-category-nav {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

// Search
.search-entry {
  position: relative;
  &:not(:last-of-type) {
    border-bottom: 1px solid $secondary;
    margin-bottom: 1.875rem;
    padding-bottom: 1.875rem;
  }
}

.elc-small {
  width: calc(100% - 6em)!important;

  h1{
    font-size:1.5em;
  }
}

