@mixin primaryBtnMixin {
  -webkit-box-shadow: 0 0 0 rgba($brand-orange, 0);
  -moz-box-shadow: 0 0 0 rgba($brand-orange, 0);
  box-shadow: 0 0 0 rgba($brand-orange, 0);
  transition: box-shadow transform 0.25s ease;
  &:hover {
    -webkit-box-shadow: .25rem .3rem 0.01rem rgba($brand-orange, 1);
    -moz-box-shadow: .25rem .3rem 0.01rem rgba($brand-orange, 1);
    box-shadow: .25rem .3rem 0.01rem rgba($brand-orange, 1);
  }
  &:focus {
    -webkit-box-shadow: .25rem .3rem 0.01rem rgba($brand-orange, 1);
    -moz-box-shadow: .25rem .3rem 0.01rem rgba($brand-orange, 1);
    box-shadow: .25rem .3rem 0.01rem rgba($brand-orange, 1);
  }
}

@mixin secondaryBtnMixin {
  -webkit-box-shadow: 0 0 0 rgba($brand-lt-blue, 0);
  -moz-box-shadow: 0 0 0 rgba($brand-lt-blue, 0);
  box-shadow: 0 0 0 rgba($brand-lt-blue, 0);
  transition: box-shadow transform 0.25s ease;
  &:hover {
    color: $brand-yellow;
    -webkit-box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
    -moz-box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
    box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
  }
  &:focus {
    color: $brand-yellow;
    -webkit-box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
    -moz-box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
    box-shadow: .25rem .3rem 0.01rem rgba($brand-lt-blue, 1);
  }
}

@mixin whiteBtnMixin {
  -webkit-box-shadow: 0 0 0 rgba($secondary, 0);
  -moz-box-shadow: 0 0 0 rgba($secondary, 0);
  box-shadow: 0 0 0 rgba($secondary, 0);
  transition: box-shadow transform 0.25s ease;
  &:hover {
    -webkit-box-shadow: .25rem .3rem 0.01rem rgba($secondary, 1);
    -moz-box-shadow: .25rem .3rem 0.01rem rgba($secondary, 1);
    box-shadow: .25rem .3rem 0.01rem rgba($secondary, 1);
  }
  &:focus {
    -webkit-box-shadow: .25rem .3rem 0.01rem rgba($secondary, 1);
    -moz-box-shadow: .25rem .3rem 0.01rem rgba($secondary, 1);
    box-shadow: .25rem .3rem 0.01rem rgba($secondary, 1);
  }
}